@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
.swiper-pagination-bullet {
  background-color: white !important;
}

@keyframes fadeMe {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.container-home {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

@keyframes spin-slow {
  0% {
    transform: rotate(12deg);
  }
  50% {
    transform: rotate(-12deg);
  }
  100% {
    transform: rotate(12deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 1s linear infinite;
}

/* AG grid styles */
/* .ag-center-cols-container {
  min-width: 100%;
} */

.header-style {
  font-family: "Poppins";
  font-weight: 600;
  justify-content: center;
  background-color: hsl(210deg 100% 90%);
}

.header-style-bold {
  font-family: "Poppins";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.1rem;
}
.ag-header-cell-label {
  justify-content: center;
}
.header-style {
  font-family: "Poppins";
  font-weight: 600;
  justify-content: center;
}
.cell-style {
  font-family: "Poppins";
  text-align: center;
}
